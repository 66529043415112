<template>
  <div>
    <div
      class="grey lighten-4 border border-gray-200 pa-4 rounded d-flex justify-space-between align-center"
      v-if="is_addon_activated('multi_vendor')"
    >
      <router-link
        v-if="order.shop.slug"
        :to="{ name: 'ShopDetails', params: { slug: order.shop.slug } }"
        class="text-reset fs-16 fw-700 lh-1"
      >
        {{ order.shop.name }}
      </router-link>
      <span class="text-end" style="margin-left: auto">
        <!-- done: 取消订单 -->
        <!-- <div
          class="fs-12 red--text c-pointer"
          v-if="
            order.delivery_status == 'pending' &&
              order.payment_status == 'unpaid'
          "
          @click="cancelOrder(order)"
        >
          {{ $t("cancel_order") }}
        </div> -->
        <div
          class="fs-12 red--text c-pointer"
          v-if="
            is_addon_activated('refund_request') &&
            !order.has_refund_request &&
            order.payment_status == 'paid' &&
            today <= order.created_at + refundSettings.refund_request_time &&
            refundSettings.refund_request_order_status.includes(
              order.delivery_status
            ) &&
            !order.products.data.find((product) => !!!product.refundable)
          "
          @click="refundRequest(order.id)"
        >
          {{ $t('request_refund') }}
        </div>
        <div v-else class="fs-12 red--text c-pointer">
          {{ $t('not_request_refund') }}
        </div>
      </span>
    </div>

    <div
      class="grey lighten-4 border border-gray-200 pa-4 rounded d-flex justify-space-between align-center"
      v-else
    >
      <span class="fs-16 fw-700 lh-1">{{ $t('order_details') }}</span>

      <!-- done: 取消订单 -->
      <!-- <div
        class="fs-12 red--text c-pointer"
        v-if="
          order.delivery_status == 'pending' &&
            order.payment_status == 'unpaid'
        "
        @click="cancelOrder(order)"
      >
        {{ $t("cancel_order") }}
      </div> -->
      <div
        class="fs-12 red--text c-pointer"
        v-if="
          is_addon_activated('refund_request') &&
          !order.has_refund_request &&
          order.payment_status == 'paid' &&
          today <= order.created_at + refundSettings.refund_request_time &&
          refundSettings.refund_request_order_status.includes(
            order.delivery_status
          ) &&
          !order.products.data.find((product) => !!!product.refundable)
        "
        @click="refundRequest(order.id)"
      >
        {{ $t('request_refund') }}
      </div>
      <div v-else class="fs-12 red--text c-pointer">
        {{ $t('not_request_refund') }}
      </div>
    </div>

    <Steps :order-details="order" />

    <div class="grey lighten-4 pa-4" v-if="order.courier_name">
      <div class="fw-700 fs-17 mb-3 text-center">
        {{ $t('tracking_information') }}
      </div>
      <v-row class="border-top border-gray-300 border-end" no-gutters>
        <v-col
          cols="12"
          md="4"
          class="border-bottom border-start border-gray-300"
        >
          <div class="px-3 py-2 border-bottom border-gray-300 fw-600">
            {{ $t('courier_name') }}
          </div>
          <div class="pa-3">{{ order.courier_name }}</div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="border-bottom border-start border-gray-300"
        >
          <div class="px-3 py-2 border-bottom border-gray-300 fw-600">
            {{ $t('tracking_number') }}
          </div>
          <div class="pa-3">{{ order.tracking_number }}</div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="border-bottom border-start border-gray-300"
        >
          <div class="px-3 py-2 border-bottom border-gray-300 fw-600">
            {{ $t('tracking_url') }}
          </div>
          <div class="pa-3">
            <a :href="order.tracking_url" target="_blank">{{ $t('track') }}</a>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="py-5">
      <v-data-table
        :headers="headers"
        :items="order.products.data"
        class=""
        hide-default-footer
        mobile-breakpoint="750"
        item-key="order_detail_id"
      >
        <template v-slot:[`item.serial`]="{ item }">
          <span class="d-block fw-600">
            {{ order.products.data.indexOf(item) + 1 }}
          </span>
        </template>
        <template v-slot:[`item.product`]="{ item }">
          <div class="d-flex align-center">
            <img
              :src="addResourceImage(item.thumbnail)"
              :alt="item.name"
              @error="imageFallback($event)"
              class="size-70px flex-shrink-0"
            />
            <div class="flex-grow-1 ms-4">
              <div class="text-truncate-2">{{ item.name }}</div>
              <div class="" v-if="item.combinations.length > 0">
                <span
                  v-for="(combination, j) in item.combinations"
                  :key="j"
                  class="me-4 py-1 fs-12"
                >
                  <span class="opacity-70">{{ combination.attribute }}</span>
                  :
                  <span class="fw-500">{{ combination.value }}</span>
                </span>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <span class="d-block fw-600">{{ item.quantity }}</span>
        </template>
        <template v-slot:[`item.unit_price`]="{ item }">
          <span class="d-block fw-600">
            {{ format_price(item.price + item.tax) }}
          </span>
        </template>

        <template v-slot:[`item.refund_status`]="{ item }">
          <span
            class="d-block fw-600"
            :class="
              item.refund_status == 0
                ? 'text-info'
                : item.refund_status == 1
                ? 'text-success'
                : item.refund_status == 2
                ? 'text-danger'
                : ''
            "
          >
            {{ refundStatus(item.refund_status) }}
          </span>
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <span class="d-block fw-600">{{ format_price(item.total) }}</span>
        </template>
        <template
          v-slot:[`item.review`]="{ item }"
          v-if="order.delivery_status == 'delivered'"
        >
          <v-btn
            v-if="item.refund_status !== 1"
            @click="openReviewDialog(item.id)"
            text
            small
            class="px-2 text-primary"
          >
            {{ $t('write_a_review') }}
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-row class="mb-5">
      <v-col xl="7" md="6" cols="12" order="2" order-md="1">
        <div v-if="order.payment_status == 'paid'" class="mt-5 ms-lg-5">
          <img :src="paid_sticker" />
        </div>
        <div
          v-else-if="order.payment_type == 'cash_on_delivery'"
          class="mt-5 ms-lg-5"
        >
          <img :src="cod_sticker" />
        </div>
      </v-col>
      <v-col xl="5" md="6" cols="12" order="1" order-md="2">
        <v-list dense>
          <v-list-item class="fw-700">
            <v-list-item-content>{{ $t('order_code') }} :</v-list-item-content>
            <v-list-item-content class="align-end col-6 justify-end">
              {{ order.code }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="fw-700">
            <v-list-item-content>{{ $t('sub_total') }} :</v-list-item-content>
            <v-list-item-content class="align-end col-4 justify-end">
              {{ format_price(order.subtotal) }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="fw-700">
            <v-list-item-content>{{ $t('tax') }} :</v-list-item-content>
            <v-list-item-content class="align-end col-4 justify-end">
              {{ format_price(order.tax) }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="fw-700">
            <v-list-item-content>
              {{ $t('shipping_charge') }} :
            </v-list-item-content>
            <v-list-item-content class="align-end col-4 justify-end">
              {{ format_price(order.shipping_cost) }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="fw-700">
            <v-list-item-content>
              {{ $t('coupon_discount') }} :
            </v-list-item-content>
            <v-list-item-content class="align-end col-4 justify-end">
              {{ format_price(order.coupon_discount) }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <div class="grey lighten-4 border border-gray-200 rounded">
          <v-list-item class="fw-700">
            <v-list-item-content>{{ $t('total') }} :</v-list-item-content>
            <v-list-item-content class="align-end col-4 justify-end px-0">
              {{ format_price(order.grand_total) }}
            </v-list-item-content>
          </v-list-item>
        </div>

        <!-- 确认收货按钮 -->
        <div v-if="order.delivery_status === 'on_the_way'" class="mt-5">
          <v-btn
            type="button"
            elevation="0"
            block
            color="primary"
            :loading="delivereing"
            :disabled="delivereing"
            @click="orderDeliveredProxy"
          >
            {{ $t('order_delivered') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <review-dialog ref="submitReview" />
    <ConfirmDialog ref="confirmCancel" />
  </div>
</template>

<script>
import Steps from './Steps'
import ReviewDialog from './ReviewDialog'
import ConfirmDialog from '../../components/inc/ConfirmDialog'
import { mapGetters } from 'vuex'
export default {
  components: { ReviewDialog, Steps, ConfirmDialog },
  props: {
    orderDetails: { type: Object, default: () => {} }
  },
  data: () => ({
    cod_sticker: require('@/assets/img/cod_sticker.svg'),
    paid_sticker: require('@/assets/img/paid_sticker.svg'),
    today: new Date().getTime() / 1000,
    delivereing: false
  }),
  computed: {
    order: {
      get() {
        return this.orderDetails
      },
      set(newVal) {
        console.log(newVal)
      }
    },
    headers() {
      const headers = [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'serial'
        },
        {
          text: this.$i18n.t('product'),
          sortable: false,
          value: 'product'
        },
        {
          text: this.$i18n.t('quantity'),
          sortable: false,
          value: 'quantity'
        },
        {
          text: this.$i18n.t('unit_price'),
          sortable: false,
          value: 'unit_price'
        },
        /**
         * 售后状态
         * -1.无 0.待处理 1.同意 2.拒绝
         */
        {
          text: this.$t('refund_status'),
          sortable: false,
          value: 'refund_status'
        },
        {
          text: this.$i18n.t('total'),
          sortable: false,
          align: 'end',
          value: 'total'
        }
      ]

      if (this.order.delivery_status == 'delivered') {
        headers.push({
          text: '',
          align: 'end',
          sortable: false,
          value: 'review'
        })
      }

      return headers
    },
    ...mapGetters('app', ['refundSettings'])
  },
  methods: {
    openReviewDialog(productId) {
      this.$refs.submitReview.open(productId)
    },
    cancelOrder(order) {
      this.$refs.confirmCancel
        .open(
          this.$i18n.t('confirm_cancel'),
          this.$i18n.t('are_you_sure_you_want_to_cancel_this_order')
        )
        .then((res) => {
          if (res) {
            this.cancelConfirmed(order.id)
          }
        })
    },
    refundRequest(order_id) {
      this.$router.push({
        name: 'CreateRefundRequest',
        params: { orderId: order_id }
      })
    },
    async cancelConfirmed(order_id) {
      this.snack({
        message: this.$i18n.t('cancelling_order'),
        timeout: -1
      })
      const res = await this.call_api('get', `user/order/cancel/${order_id}`)
      if (res.data.success) {
        this.snack({ message: res.data.message })
        this.order.delivery_status = 'cancelled'
      } else {
        this.snack({ message: res.data.message, color: 'red' })
      }
    },

    // 确认 收货代理
    orderDeliveredProxy() {
      this.$refs.confirmCancel
        .open(this.$i18n.t('tips'), this.$i18n.t('order_delivered_tips'))
        .then((res) => {
          if (res) {
            this.orderDelivered()
          }
        })
    },

    // 确认收货
    async orderDelivered() {
      this.delivereing = true

      try {
        const res = await this.call_api('post', 'order/delivered', {
          order_id: this.order.id,
          status: 'delivered'
        })

        if (res.data.success) {
          this.snack({
            message: this.$t('order_delivered_success'),
            coloe: 'green'
          })

          this.$emit('reload')
        } else {
          this.snack({ message: this.$t('order_delivered_fail'), coloe: 'red' })
        }
      } catch (e) {
        console.log('order delivered fail: ', e)
      }

      this.delivereing = false
    },

    refundStatus(status) {
      const t = {
        0: this.$t('pending'),
        1: this.$t('refund_status_success'),
        2: this.$t('refund_status_reject')
      }
      return status === -1 ? this.$t('refund_status_null') : t[Number(status)]
    }
  }
}
</script>
